$icon-font-path: "../fonts/bootstrap/";
$default-color: #1a171b;
$brand-primary: #0099cc;
$brand-success: #00cb29;
$border-radius-base: 0px;
$border-radius-large: 0px;
$border-radius-small: 0px;
// Basics of a navbar
$navbar-height: 20px;
$navbar-collapse-max-height: 340px !default;
$navbar-default-color: #1a171b;
$navbar-default-bg: #fff;
$navbar-default-border: #fff;
// Navbar links
$navbar-default-link-color: #1a171b;
$navbar-default-link-hover-color: #1a171b;
$navbar-default-link-hover-bg: transparent !default;
$navbar-default-link-active-color: #555 !default;
//$navbar-default-link-active-bg:            darken($navbar-default-bg, 6.5%) !default;
$navbar-default-link-disabled-color: #ccc !default;
$navbar-default-link-disabled-bg: transparent !default;
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,800&subset=latin-ext');
@import '../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss';
@import 'animate';
body {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 300;
    color: $default-color;
}

h1,
.headline-h1 {
    font-size: 36px;
    font-weight: 800;
    text-transform: uppercase;
    color: $brand-primary;
    margin-bottom: 30px;
}

h2,
.headline-h2 {
    font-size: 24px;
    font-weight: 300;
    text-transform: uppercase;
    color: $brand-primary;

    a {
        text-decoration: none;
    }

    small {
        font-size: 14px;
        text-transform: none;
        color: $brand-primary;
    }

    &.green {
        small, a {
            color: $brand-success;
        }
        
    }
}

h3,
.headline-h3 {
    font-size: 16px;
    font-weight: 800;
    text-transform: uppercase;
    color: $brand-primary;
    margin-top: 8px;
}

h4,
.headline-h4 {
    font-size: 14px;
    font-weight: 800;
    color: $default-color;
}

a {
    text-decoration: underline;
}

ol {
    padding-left: 15px;
    li {
        padding-left: 15px;
        padding-bottom: 10px;
    }
}

.btn {
    text-decoration: none;
}

.btn-default {
    border-color: $brand-primary;
    color: $brand-primary;
    background-color: #fff;
}

.img-circle {
    max-width: 70px;
    height: 70px;
}

input.form-control, textarea.form-control {
    box-shadow: inset 4px -4px 4px #b5b4b5;
    border: 1px solid $brand-primary;
}

.name {
    font-size: 10px;
    color: $brand-primary;
}

.green {
    color: $brand-success;
}

.small-icon {
    @media (min-width: $screen-sm) {
        float: right;
    }
}

table.table {
    tr {
        th {
            color: #fff;
            font-size: 14px;
            font-weight: 300;
            text-transform: uppercase;
            background-color: $brand-primary;
            border-color: $brand-primary;
        }
        td {
            font-size: 14px;
            border-color: $brand-primary;

            .tlabel {
                font-weight: 600;
                color: $brand-primary;
                background: url('../img/svg/ostatni/zvyrazneni_radku_modre.svg') no-repeat left center;
                padding-left: 10px;
            }
            .tlabel-n {
                font-weight: 400;
                background: url('../img/svg/ostatni/zvyrazneni_radku_modre.svg') no-repeat left center;
                padding-left: 10px;
            }
            .tlabel-lvl-2 {
                display: inline-block;
                font-weight: 400;
                background: url('../img/svg/ostatni/zvyrazneni_radku_svmodre.svg') no-repeat left center;
                padding-left: 10px;
                margin-left: 25px;
                margin-top: 10px;
            }
        }
    }

    &.table-green {
        th {
            background-color: $brand-success;
            border-color: $brand-success;
        }
        td {
            border-color: $brand-success;

            .tlabel {
                color: $brand-success;
                background: url('../img/svg/ostatni/zvyrazneni_radku_zelene.svg') no-repeat left center;
                padding-left: 10px;
            }
            .tlabel-n {
                font-weight: 400;
                background: url('../img/svg/ostatni/zvyrazneni_radku_zelene.svg') no-repeat left center;
                padding-left: 10px;
            }
        }
    }
}

.decoration-area {
    display: block;
    width: 100%;
    height: 204px;
    margin-bottom: 30px;
    background-image: url('../img/foto/tablets.jpg');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
}

.navbar-default {
    margin-top: 10px;
    margin-bottom: 40px;
    .navbar-nav {
        &>li {
            &>a {
                font-size: 14px;
                font-weight: 400;
                border-right: 1px solid $brand-primary;
                text-decoration: none;
                img.professionals-icon {
                    margin-top: 10px;
                    @media (min-width: $screen-lg) {
                        margin-top: -10px;
                    }
                }
                @media (min-width: $screen-sm) and (max-width: $screen-md+1px) {
                    font-size: 13px;
                }
            }
            &:last-of-type {
                &>a {
                    border-right: 0px;
                }
            }
        }
    }
    .navbar-right {
        margin-right: -30px;
    }
}

.header {
    margin-bottom: 20px;
    .logo {
        @media (max-width: $screen-sm + 1px) {
            margin-left: auto;
            margin-right: auto;
        }
    }
    .search-form {
        margin-top: 46px;
        input.form-control {
            box-shadow: inset 4px -4px 4px #b5b4b5;
            @media (max-width: $screen-sm + 1px) {
                float: left;
            }
        }
        .input-group {
            float: left;
            margin: 0px;
        }
        .search-button {
            padding: 1px 0px 1px 0px;
            margin: 0px 0px 0px 0px;
            float: left;
            &:hover {
                background-color: $brand-primary;
            }
        }
        @media (min-width: $screen-sm) {
            float: right;
        }
        @media (max-width: $screen-sm+1px) {
            width: 220px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.carousel {
    display: none;
    .carousel-inner {
        &>.item {
            .slide {
                display: block;
                width: 100%;
                height: 322px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
    }
    @media (min-width: $screen-sm) {
        display: block;
    }
}

.blue-area {
    min-height: 206px;
    width: 100%;
    background-color: $brand-primary;
    color: #fff;
    margin-bottom: 30px;
    .welcome-label {
        font-size: 30px;
        color: #84d0f0;
        font-weight: 800;
        margin-top: 15px;
    }
    .welcome-headline {
        font-size: 30px;
        text-transform: uppercase;
        font-weight: 300;
        @media (min-width: $screen-sm) {
            font-size: 44px;
        }
    }
    @media (min-width: 1000px) {
        background-image: url('../img/svg/ostatni/piktogramy_pas.svg'), url('../img/svg/ostatni/piktogramy_pas.svg');
        background-repeat: no-repeat, no-repeat;
        background-position: -250% center, 350% center;
    }
    @media (min-width: 1100px) {
        background-image: url('../img/svg/ostatni/piktogramy_pas.svg'), url('../img/svg/ostatni/piktogramy_pas.svg');
        background-repeat: no-repeat, no-repeat;
        background-position: -150% center, 250% center;
    }
    @media (min-width: 1200px) {
        background-image: url('../img/svg/ostatni/piktogramy_pas.svg'), url('../img/svg/ostatni/piktogramy_pas.svg');
        background-repeat: no-repeat, no-repeat;
        background-position: -100% center, 200% center;
    }
    @media (min-width: 1400px) {
        background-image: url('../img/svg/ostatni/piktogramy_pas.svg'), url('../img/svg/ostatni/piktogramy_pas.svg');
        background-repeat: no-repeat, no-repeat;
        background-position: -50% center, 150% center;
    }
    @media (min-width: 1540px) {
        background-image: url('../img/svg/ostatni/piktogramy_pas.svg'), url('../img/svg/ostatni/piktogramy_pas.svg');
        background-repeat: no-repeat, no-repeat;
        background-position: -30% center, 130% center;
    }
}

.interest-area {
    width: 100%;
    min-height: 400px;
    background: #fff url('../img/foto/doctor.jpg') no-repeat right center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.green-area {
    min-height: 198px;
    width: 100%;
    background-color: $brand-success;
    color: #fff;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 30px;
    blockquote {
        font-size: 20px;
        font-weight: 300;
        border: 0px;
        max-width: 500px;
        margin: 0px auto;
        background-image: url('../img/svg/ostatni/uvozovky_01.svg'), url('../img/svg/ostatni/uvozovky_02.svg');
        background-repeat: no-repeat, no-repeat;
        background-position: left top, right bottom;
        strong {
            font-weight: 800;
        }
    }
}

.news-area {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
    .news {
        flex: 1 200px;
        max-width: 200px;
        margin: 10px;
        text-align: center;
        .date-line {
            text-align: center;
            margin-top: 10px;
            margin-bottom: 0px;
        }
        h4, .headline-h4 {
            margin-top: 10px;
        }
    }
}

img.news-detail-img {
    margin-bottom: 10px;
    @media (min-width: $screen-sm) {
        max-width: 400px;
    }
    
}

.hp-content {
    background: #fff url('../img/foto/bg-hp-content.jpg') no-repeat center bottom;
    background-attachment: fixed;
    background-size: 100% 374px;
    padding: 0px 0px 30px 0px;
}

.content {
    background: #fff url('../img/foto/content-bg.jpg') no-repeat center bottom;
    background-size: 100% 290px;
    padding: 0px 0px 100px 0px;
}

footer {
    border-top: 2px solid $brand-success;
    padding: 30px 0px;
    ul {
        li {
            border-right: 1px solid $default-color;
            line-height: 1;
            &:last-of-type {
                border-right: 0px;
            }
            a {
                color: inherit;
                text-decoration: none;
                font-size: 12px;
                font-weight: 400;
                padding-right: 5px;
                &:hover {
                    color: inherit;
                }
            }
        }
    }

    img.banner {
        @media (min-width: $screen-md) {
            max-width: 400px;
        }
    }
}